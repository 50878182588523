import React      from 'react';
import Container  from 'react-bootstrap/Container';
import Provider   from '../tools/Provider';
import Query      from '../tools/Query';
import graphql    from '../../graphql';
import Sunburst   from './Sunburst';
import Details    from './Details';

const Overview = (props) =>
	<Container>
		<Provider uri={ props.network.subgraph }>
			<Query query={ graphql.overview }>
				<Sunburst className='my-4' {...props} />
				<Details  className='my-4' {...props} />
			</Query>
		</Provider>
	</Container>

export default Overview;
