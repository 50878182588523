import React from 'react';
import Table from 'react-bootstrap/Table';

const Functions = (props) =>
	<div className={props.className}>
		<h3 className='text-center'>Functions</h3>
		<Table responsive hover size='sm'>
			<thead>
				<tr>
					<th>#</th>
					<th>Selector</th>
					<th>ABI</th>
				</tr>
			</thead>
			<tbody>
				{
					!props.data.erc1538Module.functions.length &&
					<tr>
						<td colSpan={3}>
							No active function on this module
						</td>
					</tr>
				}
				{
					props.data.erc1538Module.functions.map((fun, i) =>
						<tr key={i}>
							<td>
								{ i+1 }
							</td>
							<td>
								<a href={`#/${props.network.key}/method/${fun.id}`}>
									<code>
										{ fun.id }
									</code>
								</a>
							</td>
							<td>
								<a href={`#/${props.network.key}/method/${fun.id}`}>
									<code>
										{ fun.name }
									</code>
								</a>
							</td>
						</tr>
					)
				}
			</tbody>
		</Table>
	</div>

export default Functions;
