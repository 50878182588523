import React from 'react';

import Highcharts from 'highcharts'
import highchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsReact from 'highcharts-react-official'
highchartsSunburst(Highcharts)

const Sunburst = (props) =>
	<div className={props.className}>
		<HighchartsReact
			highcharts={Highcharts}
			options={{
				title:
				{
					text: null, //'ERC1538 configuration'
				},
				chart:
				{
					height: '75%'
				},
				series:
				[{
					type: "sunburst",
					data: [
						{
							id:     'root',
							parent: '',
							name:   'iExecProxy',
							descr:  'iExecProxy'
						},
							...props.data.erc1538Modules
							.filter(({ id }) => id !== "0x0000000000000000000000000000000000000000")
							.map(module => ({
								id:     module.id,
								parent: 'root',
								name:   module.id,
								descr:  `module <b>${module.id}</b>`
							})),
							...props.data.erc1538Modules
							.filter(({ id }) => id !== "0x0000000000000000000000000000000000000000")
							.map(module =>
								module.functions
								.map(fun => ({
									id:     fun.id,
									parent: module.id,
									name:   fun.name,
									value:  1,
									descr:  `method <b>${fun.name}</b>`
								}))
							).reduce((acc, val) => acc.concat(val), []),
						],
					allowDrillToNode: true,
					cursor: 'pointer',
					dataLabels:
					{
						format: '{point.name}',
						filter:
						{
							property: 'innerArcLength',
							operator: '>',
							value: 16
						},
						rotationMode: 'circular'
					},
					levels:
					[
						{
							level: 1,
							color: '#ffffff'
						},
						{
							level: 2,
							colorByPoint: true
						},
						{
							level: 3,
							colorVariation:
							{
								key: 'brightness',
								to: -0.5
							}
						}
					]
				}],
				tooltip: {
					headerFormat: '',
					pointFormat:  '{point.descr}'
				}
			}}
		/>
	</div>;

export default Sunburst;
