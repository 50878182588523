import gql from 'graphql-tag';

export default gql`
{
	erc1538Modules(first: 1000)
	{
		id
		functions
		{
			id
			name
			module
			{
				id
			}
		}
	}
}
`
