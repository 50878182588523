import gql from 'graphql-tag';

export default gql`
query methods($module: String)
{
	erc1538Module(id: $module)
	{
		id
		functions
		{
			id
			name
		}
	}
}
`
