import React from 'react';
import Table from 'react-bootstrap/Table';

const Details = (props) =>
	<div className={props.className}>

		<h3 className='text-center'>Modules</h3>

		<Table responsive hover size='sm'>
			<thead>
				<tr>
					<th>#</th>
					<th>Address</th>
					<th>Methods</th>
				</tr>
			</thead>
			<tbody>
				{
					props.data.erc1538Modules
					.filter(({ id        }) => id !== "0x0000000000000000000000000000000000000000")
					.filter(({ functions }) => functions.length)
					.map((module, i) =>
						<tr key={i}>
							<td>
								{ i+1 }
							</td>
							<td>
								<a href={`#/${props.network.key}/module/${module.id}`}>
									<code>
										{ module.id }
									</code>
								</a>
							</td>
							<td>
								{ module.functions.length }
							</td>
						</tr>
					)
				}
			</tbody>
		</Table>

		<h3 className='text-center'>Functions</h3>

		<Table responsive hover size='sm'>
			<thead>
				<tr>
					<th>#</th>
					<th>Selector</th>
					<th>ABI</th>
					<th>Active</th>
				</tr>
			</thead>
			<tbody>
				{
					props.data.erc1538Modules
					.map(module => module.functions)
					.reduce((acc, val) => acc.concat(val), [])
					.sort((fun1, fun2) => fun1.id > fun2.id)
					.sort((fun1, fun2) => fun1.module.id === "0x0000000000000000000000000000000000000000")
					.map((fun, i) =>
						<tr key={i}>
							<td>
								{ i+1 }
							</td>
							<td>
								<a href={`#/${props.network.key}/method/${fun.id}`}>
									<code>
										{ fun.id }
									</code>
								</a>
							</td>
							<td>
								<a href={`#/${props.network.key}/method/${fun.id}`}>
									<code>
										{ fun.name }
									</code>
								</a>
							</td>
							<td>
								{ String(fun.module.id !== "0x0000000000000000000000000000000000000000") }
							</td>
						</tr>
					)
				}
			</tbody>
		</Table>

	</div>;

export default Details;
