import React from 'react';
import Table from 'react-bootstrap/Table';

const Details = (props) =>
	<div className={props.className}>
		<h3 className='text-center'>Module details</h3>
		<Table responsive hover size='sm'>
			<tbody>
				<tr>
					<td>
						Contract
					</td>
					<td>
						<a href={`${props.network.explorer}/address/${props.data.erc1538Module.id}`} target='_blank' rel='nofollow noopener noreferrer'>
							<code>
								{ props.data.erc1538Module.id }
							</code>
						</a>
					</td>
				</tr>
			</tbody>
		</Table>
	</div>

export default Details;
