import gql from 'graphql-tag';

export default gql`
query methods($selector: String)
{
	erc1538FunctionUpdates(first: 1000, orderBy: timestamp, orderDirection: desc, where: { functionId: $selector })
	{
		functionId
		{
			id
			name
			module
			{
				id
			}
		}
		timestamp
		oldmodule
		{
			id
		}
		newmodule
		{
			id
		}
	}
}
`
