import React      from 'react';
import Container  from 'react-bootstrap/Container';
import Provider   from '../tools/Provider';
import Query      from '../tools/Query';
import graphql    from '../../graphql';
import Details    from './Details';
import History    from './History';

const Method = (props) =>
	<Container>
		<Provider uri={ props.network.subgraph }>
			<Query query={ graphql.method } variables={{ selector: props.method }}>
				<Details className='my-4' {...props}/>
				<History className='my-4' {...props}/>
			</Query>
		</Provider>
	</Container>

export default Method;
