import React       from 'react';
import Nav         from 'react-bootstrap/Nav';
import Navbar      from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import iExecLogo from '../../assets/iExec-RLC-logo.svg'

const Navigation = (props) =>
	<Navbar bg='dark' variant='dark' sticky='top' className='shadow'>
		<Navbar.Brand href='#'>
			<img
				alt=''
				src={iExecLogo}
				width='30'
				height='30'
				className='d-inline-block align-top'
			/>{' '}
			iExec ERC1538 monitoring
		</Navbar.Brand>
		<Nav className='mr-auto'>
			<Nav.Link href={`#/${props.network.key}`}>Overview</Nav.Link>
			<Nav.Link href='https://github.com/ethereum/EIPs/blob/master/EIPS/eip-1538.md'>Specification</Nav.Link>
			<Nav.Link href='https://github.com/iExecBlockchainComputing/iexec-solidity/'>Implementation</Nav.Link>
		</Nav>
		<Nav>
			<NavDropdown title={ props.network.name }>
				{
					Object.entries(props.config.networks).map(([ key, network ]) => <NavDropdown.Item key={key} href={`#/${key}`}>{ network.name }</NavDropdown.Item>)
				}
			</NavDropdown>
		</Nav>
	</Navbar>

export default Navigation;
