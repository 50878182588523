import React from 'react';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';

import Navigation from './Navigation/';
import Overview   from './Overview/';
import Method     from './Method/';
import Module     from './Module/';

const Core = (props) =>
{
	return (
		<Router>
			<Route exact path='/'><Redirect to={`/${Object.keys(props.config.networks).find(Boolean)}`}/></Route>
			<Route       path='/:network'                render={ (routing) => <Navigation network={{ key: routing.match.params.network, ...props.config.networks[routing.match.params.network] }}                                      {...props} /> }/>
			<Route exact path='/:network'                render={ (routing) => <Overview   network={{ key: routing.match.params.network, ...props.config.networks[routing.match.params.network] }}                                      {...props} /> }/>
			<Route exact path='/:network/method/:method' render={ (routing) => <Method     network={{ key: routing.match.params.network, ...props.config.networks[routing.match.params.network] }} method={routing.match.params.method} {...props} /> }/>
			<Route exact path='/:network/module/:module' render={ (routing) => <Module     network={{ key: routing.match.params.network, ...props.config.networks[routing.match.params.network] }} module={routing.match.params.module} {...props} /> }/>
		</Router>
	);
}

export default Core;
