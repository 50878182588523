import React      from 'react';
import Container  from 'react-bootstrap/Container';
import Provider   from '../tools/Provider';
import Query      from '../tools/Query';
import graphql    from '../../graphql';
import Details    from './Details';
import Functions  from './Functions';


const Module = (props) =>
	<Container>
		<Provider uri={ props.network.subgraph }>
			<Query query={ graphql.module } variables={{ module: props.module }}>
				<Details   className='my-4' {...props} />
				<Functions className='my-4' {...props} />
			</Query>
		</Provider>
	</Container>

export default Module;
