import React from 'react';
import Table from 'react-bootstrap/Table';

const History = (props) =>
	<div className={props.className}>
		<h3 className='text-center'>History</h3>
		<Table responsive hover size='sm'>
			<thead>
				<tr>
					<th>Date</th>
					<th>Old module</th>
					<th>New module</th>
				</tr>
			</thead>
			<tbody>
				{
					props.data.erc1538FunctionUpdates.map((update, i) =>
						<tr key={i}>
							<td>
								{ (new Date(Number(update.timestamp)*1000)).toLocaleString() }
							</td>
							<td>
								<a href={`#/${props.network.key}/module/${update.oldmodule.id}`}>
									<code>
										{ update.oldmodule.id }
									</code>
								</a>
							</td>
							<td>
								<a href={`#/${props.network.key}/module/${update.newmodule.id}`}>
									<code>
										{ update.newmodule.id }
									</code>
								</a>
							</td>
						</tr>
					)
				}
			</tbody>
		</Table>
	</div>

export default History;
