import React from 'react';
import Table from 'react-bootstrap/Table';

const Details = (props) =>
	<div className={props.className}>
		<h3 className='text-center'>Function details</h3>
		<Table responsive hover size='sm'>
			<tbody>
				<tr>
					<td>
						Function selector
					</td>
					<td>
						<code>
							{ props.data.erc1538FunctionUpdates.find(Boolean).functionId.id }
						</code>
					</td>
				</tr>
				<tr>
					<td>
						ABI
					</td>
					<td>
						<code>
							{ props.data.erc1538FunctionUpdates.find(Boolean).functionId.name }
						</code>
					</td>
				</tr>
				<tr>
					<td>
						Current module
					</td>
					<td>
						<a href={`#/${props.network.key}/module/${props.data.erc1538FunctionUpdates.find(Boolean).functionId.module.id}`}>
							<code>
								{ props.data.erc1538FunctionUpdates.find(Boolean).functionId.module.id }
							</code>
						</a>
					</td>
				</tr>
			</tbody>
		</Table>
	</div>

export default Details;
